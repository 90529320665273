<template>
  <main >
    <home-hero></home-hero>
    
    <home-why id="home-why"></home-why>
    <home-education id="home-education"></home-education>
    <home-data id="home-data"></home-data>
    <home-contest id="home-contest"></home-contest>
    <home-testimonials id="home-testimonials"></home-testimonials>
  </main>
</template>

<script>
import HomeHero from "../components/home/HomeHero.vue";

import HomeWhy from "../components/home/HomeWhy.vue";
import HomeEducation from "../components/home/HomeEducation.vue";
import HomeData from "../components/home/HomeData.vue";
import HomeContest from '../components/home/HomeContest.vue';
import HomeTestimonials from "../components/home/HomeTestimonials.vue";


export default {
  components: {
    "home-hero": HomeHero,
    "home-why": HomeWhy,
    "home-education": HomeEducation,
    "home-data": HomeData,
    "home-contest": HomeContest,
    "home-testimonials": HomeTestimonials,
    
  },
};
</script>

